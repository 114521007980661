import React, { Component } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/blockGenerator"
// eslint-disable-line
import { CoreBlockFragment } from "@/components/CoreBlock" // eslint-disable-line
import { ButtonLink } from "@/components/reusable/Button"

class Post extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wpPost.Meta.title
      ? this.props.data.wpPost.Meta.title
      : this.props.data.wpPost.title
    this.props.pageContext.date = this.props.data.wpPost.date
    this.props.pageContext.cats = this.props.data.wpPost.categories
    const blocks = buildBlocks(
      this.props.data.wpPost.blocks,
      {},
      this.props.pageContext
    )

    if (this.props.data.wpPost.Meta.ogImage === null) {
      this.props.data.wpPost.Meta.ogImage =
        this.props.data.wpPost.featuredImage?.node
    }

    if (this.props.data.wpPost.Meta.twitterImage === null) {
      this.props.data.wpPost.Meta.twitterImage =
        this.props.data.wpPost.featuredImage?.node
    }

    return (
      <Layout
        meta={this.props.data.wpPost.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPost.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {blocks}
        {this.props.data.wpPost.floatingButton.showButton && (
          <div
            className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
          >
            <ButtonLink
              className={``}
              link={this.props.data.wpPost.floatingButton?.buttonLink?.url}
              variant={`primary`}
            >
              {this.props.data.wpPost.floatingButton?.buttonLink?.title}
            </ButtonLink>
          </div>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
        ogImage {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Post
