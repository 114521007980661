import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
// import Breadcrumb from "../components/Breadcrumb"
import ProductPage from "../components/ProductPage"
// import CookiesConsent from "../components/CookiesConsent"
import { isBrowser } from "../utils/helpers"

class Product extends Component {
  constructor(props) {
    super(props)

    if (!isBrowser) return
  }

  getCategories() {
    let catStack = []

    if (this.props.data.wpProduct.productCategories !== null) {
      if (this.props.data.wpProduct.productCategories.nodes.length === 1) {
        catStack.push(this.props.data.wpProduct.productCategories.nodes[0])
      } else {
        this.props.data.wpProduct.productCategories.nodes.forEach(
          (category) => {
            if (category.ancestors !== null) {
              if (category.ancestors.nodes.length >= catStack.length) {
                catStack = [
                  {
                    id: category.id,
                    name: category.name,
                    slug: category.slug,
                    uri: category.uri,
                  },
                ]
                category.ancestors.nodes.forEach((ancestor) => {
                  catStack.push(ancestor)
                })
              }
            }
          }
        )
      }
    }

    if (catStack.length > 1) {
      catStack.reverse()
    }

    return catStack
  }

  render() {
    return (
      <Layout
        meta={{
          ...this.props.data.wpProduct.Meta,
          ogImage: this.props.data.wpProduct.ProductData.featuredImage,
          twitterImage: this.props.data.wpProduct.ProductData.featuredImage,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpProduct.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {/* <Breadcrumb
          type="product"
          current={{
            title: this.props.data.wpProduct.title,
            slug: this.props.data.wpProduct.slug,
            uri: this.props.data.wpProduct.uri,
          }}
          ancestors={this.getCategories()}
        />
      */}

        <ProductPage
          categories={this.getCategories()}
          productData={this.props.data.wpProduct.ProductData}
          uri={this.props.data.wpProduct.uri}
          params={this.props.location.search}
          allProducts={this.props.data.allWpProduct}
        />
        {/* <CookiesConsent></CookiesConsent> */}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String) {
    wpProduct(id: { eq: $id }, status: { eq: "publish" }) {
      id
      slug
      title
      uri
      databaseId
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
      ProductData {
        productIdBase64
        comparePrice
        description
        featuredImage
        options
        price
        images {
          image
        }
        title
        variants {
          inStock
          comparePrice
          image
          option1
          option2
          option3
          price
          quantity
          sku
          title
          weight
          variantIdBase64
          variantId
        }
      }
      productCategories {
        nodes {
          id
          name
          slug
          uri
          ancestors {
            nodes {
              id
              name
              slug
              uri
            }
          }
        }
      }
    }
    allWpProduct {
      edges {
        node {
          id
          slug
          title
          link
          ProductData {
            comparePrice
            description
            featuredImage
            options
            price
            images {
              image
            }
            productIdBase64
            title
            variants {
              inStock
              comparePrice
              image
              option1
              option2
              option3
              price
              quantity
              sku
              title
              weight
              variantIdBase64
              variantId
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Product
