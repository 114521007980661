import React, { Component } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/blockGenerator"
// eslint-disable-line
import { CoreBlockFragment } from "@/components/CoreBlock" // eslint-disable-line
import { ButtonLink } from "@/components/reusable/Button"

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListPosts extends Component {
  render() {
    this.props.pageContext.blogPath = this.props.data.wp.themeOptions.blogPath
    const blocks = buildBlocks(
      this.props.data.wpPage.blocks,
      { posts: this.props.data.allWpPost.edges },
      { posts: this.props.data.allWpPost.edges, ...this.props.pageContext }
    )

    this.props.data.wpPage.Meta.canonical = this.props.pageContext.pagePath

    return (
      <Layout
        meta={this.props.data.wpPage.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPage.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {blocks}
        {this.props.data.wpPage.floatingButton.showButton && (
          <div
            className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
          >
            <ButtonLink
              className={``}
              link={this.props.data.wpPage.floatingButton?.buttonLink?.url}
              variant={`primary`}
            >
              {this.props.data.wpPage.floatingButton?.buttonLink?.title}
            </ButtonLink>
          </div>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String, $ids: [Int]) {
    wpPage(slug: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
        ogImage {
          localFile {
            publicURL
          }
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
    }
    allWpPost(
      filter: { databaseId: { in: $ids } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          excerpt
          date(formatString: "DD MMMM YYYY")
          categories {
            nodes {
              id
              termTaxonomyId
              name
            }
          }
          postReadingTime {
            readingTime
          }
          featuredImage {
            node {
              mediaItemUrl
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 960
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        blogPath
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default ListPosts
