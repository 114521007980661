import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { buildBlocks } from "../services/blockGenerator"
import { ButtonLink } from "@/components/reusable/Button"
import { useRecurringEvents } from "@/hooks/useRecurringEvents"

export default function Post(props) {
  const { title, recurrenceId } = props.data.wpEvent

  props.pageContext.pageTitle = title
  props.pageContext.event = props.data.wpEvent

  const recurringEvents = useRecurringEvents()

  if (recurrenceId) {
    const recurrence = recurringEvents.find(
      (event) => event.recurrenceId === recurrenceId
    )
    props.pageContext.event.dates = recurrence.dates
  }

  const blocks = buildBlocks(
    props.data.wpPage.blocks,
    {},
    { ...props.pageContext }
  )

  if (!blocks) return null

  return (
    <Layout
      meta={props.data.wpEvent}
      path={props.pageContext.pagePath}
      title={props.data.wpEvent.title}
      themeOptions={props.data.wp.themeOptions}
    >
      {blocks}
      {props.data.wpPage.floatingButton.showButton && (
        <div
          className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
        >
          <ButtonLink
            className={``}
            link={props.data.wpPage.floatingButton?.buttonLink?.url}
            variant={`primary`}
          >
            {props.data.wpPage.floatingButton?.buttonLink?.title}
          </ButtonLink>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String, $eventPage: String) {
    wpPage(slug: { eq: $eventPage }) {
      id
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
    }
    wpEvent(id: { eq: $id }) {
      title
      uri
      slug
      startDate
      startTime
      displayStartDate
      displayStartTime
      isAllDay
      recurrenceId
      content
      excerpt
      eventTickets {
        hasTickets
        ticketPrice
        ticketLink
      }
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1140
                aspectRatio: 2
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
