import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import { graphql } from "gatsby"
import Wrapper from "@/components/reusable/Wrapper"

import { useUX } from "@/hooks/useUX"
import { ux } from "@/styles/lookups"

import Layout from "@/components/Layout"
import { handleLogin, mutationLogin } from "@/services/auth"
import { isBrowser } from "@/utils/helpers"
import { HAS_SHOP } from "@/lib/constants"

export default function Login({ data }) {
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)

  function handleSubmit(e) {
    if (e) e.preventDefault()

    if (!isBrowser) return false

    setProcessing(true)

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        mutationLogin({ login, password }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.errors) {
          const { message } = res.data.errors[0]
          if (
            message === "incorrect_password" ||
            message === "invalid_username" ||
            message === "invalid_email"
          ) {
            setError("Incorrect login details")
            setProcessing(false)
          }

          return null
        }

        setError("")
        setProcessing(false)

        const {
          firstName = "",
          lastName = "",
          email = "",
          username,
        } = res.data.data.login.user

        handleLogin({
          login: username,
          firstName,
          lastName,
          email,
          id: res.data.data.login.user.id,
          token: res.data.data.login.authToken,
          refreshToken: res.data.data.login.refreshToken,
        })

        navigate("/account/offers")
      })
      .catch((err) => console.error(err))
  }

  const uxProps = useUX()

  if (!HAS_SHOP) return null

  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/account/login/",
      }}
      path={"a"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <div
        className={`login-page-title mx-auto my-0 mb-10 mb-4 bg-theme-primary pb-9 pt-12 md:mb-16 md:pb-16 md:pt-20 [&_.gatsby-image-wrapper]:mx-3 [&_.gatsby-image-wrapper]:mb-4 [&_.gatsby-image-wrapper]:md:mt-12 [&_p.text-center]:md:mx-1/12 [&_p]:mb-4 [&_p]:md:mr-1/6 [&_p]:md:max-w-5/6`}
      >
        <Wrapper>
          <h1
            className={`relative mx-auto mb-2 block w-full max-w-wrapper px-3 font-header text-5xl font-normal leading-[1.16] text-theme-primary-contrast lg:text-[68px] lg:leading-[1.05]`}
          >
            Login to your account
          </h1>
        </Wrapper>
      </div>
      <Wrapper>
        <form
          onSubmit={handleSubmit}
          className={`flex-set-0-0 mx-auto mb-40 basis-full md:mb-16 md:basis-2/3 lg:mb-20 lg:basis-1/2`}
        >
          <p
            className={`relative mx-auto mb-4 block w-full max-w-wrapper px-3 font-body`}
          >
            Please complete your login details below to view your secure area:
          </p>
          <div className={`relative overflow-hidden px-3 font-body text-[0px]`}>
            <label
              className={`mb-3 block font-body text-xs uppercase leading-snug tracking-[0.152em] text-theme-black transition-all`}
              htmlFor="login"
            >
              Login
            </label>
            <input
              name="login"
              type="text"
              placeholder="Login"
              onChange={(e) => setLogin(e.target.value)}
              style={{ background: "white" }}
              className={`focus:bg-theme-white/85 m-0 mb-3 w-full appearance-none rounded-lg border border-solid bg-theme-white px-4 py-3 font-body text-sm leading-normal text-theme-black outline-none transition-all focus:outline-none`}
            ></input>
          </div>
          <div className={`relative overflow-hidden px-3 font-body text-[0px]`}>
            <label
              className={`mb-3 block font-body text-xs uppercase leading-snug tracking-[0.152em] text-theme-black transition-all`}
              htmlFor="login"
            >
              Password
            </label>
            <input
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              style={{ background: "white" }}
              className={`focus:bg-theme-white/85 m-0 mb-3 w-full appearance-none rounded-lg border border-solid bg-theme-white px-4 py-3 font-body text-sm leading-normal text-theme-black outline-none transition-all focus:outline-none`}
            ></input>
          </div>

          {processing && <div className="logging-in">Processing</div>}
          {!!error && <div>{error}</div>}
          <button
            type="submit"
            className={`brew-button primary relative mb-4 ml-auto mr-3 mt-4 block max-w-inner border-theme-accent bg-theme-accent px-6  py-2 font-subHeader text-lg font-bold leading-normal text-theme-accent-contrast transition-colors hover:bg-theme-accent-contrast hover:text-theme-accent focus:bg-theme-accent-contrast focus:text-theme-accent focus:outline-none ${
              ux[`border-${uxProps.borders.thickness}`]
            } ${ux[`radius-${uxProps.borders.radius}`]} ${
              ux[`shadow-${uxProps.shadow.depth}-${uxProps.shadow.colour}`]
            }`}
          >
            Login
          </button>
        </form>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
