import React from "react"
import { cn } from "@/utils/helpers"

const Container = ({
  className,
  children,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <div className={cn(`container mx-auto`, className)}>{children}</div>
}

export default Container
