import "./src/styles/global.css"

// export const onRouteUpdate = ({ location }) => scrollToAnchor(location)
export const onServiceWorkerUpdateReady = () => window.location.reload()

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

// function scrollToAnchor(location, mainNavHeight = 200) {
//   window.scrollTo({ top: 0 })
//   if (location && location.hash) {
//     setTimeout(function () {
//       const item = document.getElementById(`${location.hash.replace("#", "")}`)
//       if (item) {
//         window.scrollTo({
//           top: item.getBoundingClientRect().top - mainNavHeight,
//           behavior: "smooth",
//         })
//       }
//     }, 750)
//   }
//   return true
// }
