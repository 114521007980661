import React from "react"
import { cn } from "@/utils/helpers"

type PriceProps = {
  price: number
  rrpPrice?: number
  className?: string
  spanClassName?: string
}

export function convertToCurrency(price: number) {
  if (!price) {
    return null
  }
  return price.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  })
}

const Price = ({ price, rrpPrice, className, spanClassName }: PriceProps) => {
  return (
    <div
      className={cn(
        `price mb-6 block font-header text-lg leading-6 tracking-tight`,
        className
      )}
    >
      <span className={cn(`font-black`, spanClassName)}>
        {convertToCurrency(price)}
      </span>
      {rrpPrice ? (
        <span
          className={`old ml-3 font-light tracking-[-0.016em] text-theme-primary`}
        >
          RRP <span className="">{convertToCurrency(rrpPrice)}</span>
        </span>
      ) : null}
    </div>
  )
}

export default Price
