import React, { useState } from "react"
import { useEffect } from "react"
import Layout from "@/components/Layout"
import Container from "@/components/reusable/Container"
// import Breadcrumb from "@/components/Breadcrumb"

import { isBrowser } from "@/utils/helpers"
import { useCartStore } from "@/utils/store/store"

export default function OrderConfirmation() {
  const [order, setOrder] = useState<any[]>([])
  const clearCart = useCartStore((state) => state.clearCart)

  if (!isBrowser) return

  useEffect(() => {
    // get values from url
    const urlParams = new URLSearchParams(window.location.search)
    // put all values in an object and parse
    const orderDetails = Object.fromEntries(urlParams.entries())

    // convert object to array
    const orderDetailsArray = Object.entries(orderDetails)

    if (orderDetails.success) {
      setOrder(orderDetailsArray)
      // if success, clear cart
      clearCart()
    }
  }, [])

  return (
    <Layout path={""}>
      <Container>
        <h1 className="text-3xl">Order Confirmation</h1>
      </Container>
    </Layout>
  )
}
