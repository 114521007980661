import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"

import { isLoggedIn, getUser, logout } from "@/services/auth"
import { isBrowser } from "@/utils/helpers"

import Layout from "@/components/Layout"
import { HAS_SHOP } from "@/lib/constants"

function SalesOffers(props) {
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const [salesOffers, setSalesOffers] = useState(null)

  useEffect(() => {
    if (isBrowser) {
      handleAuthentication()
    }
  }, [])

  async function handleAuthentication() {
    const response = await isLoggedIn()

    if (response.data.errors) {
      navigate("/account/login")
    } else {
      setAuthenticated(true)
    }
  }

  function handleLogout() {
    logout(() => navigate("/account/login"))
  }

  const { firstName, lastName, login } = getUser()
  // const { loading } = this.state

  if (!HAS_SHOP) return null

  return (
    <Layout
      meta={{
        ...props.data.wpPage.Meta,
        canonical: "/account/offers/",
      }}
      path={"a"}
      cta={"a"}
      themeOptions={props.data.wp.themeOptions}
    >
      {!login && <p>Redirecting...</p>}
      {firstName && (
        <p>
          Hello {firstName} {lastName}
        </p>
      )}
      <button onClick={() => handleLogout()}>Sign Out</button>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default SalesOffers
