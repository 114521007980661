import React from "react"
import Layout from "../components/Layout"
import Page404 from "../components/404"

const NotFoundPage = () => (
  <Layout meta={{}} title="Page not found">
    <Page404 />
  </Layout>
)

export default NotFoundPage
